import React from "react";
import { H1, H2, H3, P, Container } from "components/core";
import Layout from "components/Layout";
import Seo from "../components/Seo";

const AppPrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <Container size={3}>
      <H1>Privacy Policy</H1>
      <P>Last updated: January 18, 2022</P>
      <P>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </P>
      <P>
        By using the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy. This Privacy Policy has been
        created with the help of the Privacy Policy Template.
      </P>
      <H1>Interpretation and Definitions</H1>
      <H2>Interpretation</H2>
      <P>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </P>
      <H2>Definitions</H2>
      <P>For the purposes of this Privacy Policy:</P>
      <ul>
        <li>
          <P>
            <strong>Account</strong> means a unique account created for You to
            access our Service or parts of our Service.
          </P>
        </li>
        <li>
          <P>
            <strong>Affiliate</strong> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </P>
        </li>
        <li>
          <P>
            <strong>Application</strong> means the software program provided by
            the Company downloaded by You on any electronic device, named
            Patinoires Montréal
          </P>
        </li>
        <li>
          <P>
            <strong>Company</strong> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Patinoires Montréal.
          </P>
        </li>
        <li>
          <P>
            <strong>Country</strong> refers to: Quebec, Canada
          </P>
        </li>
        <li>
          <P>
            <strong>Device</strong> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
          </P>
        </li>
        <li>
          <P>
            <strong>Personal Data</strong> is any information that relates to an
            identified or identifiable individual.
          </P>
        </li>
        <li>
          <P>
            <strong>Service</strong> refers to the Application.
          </P>
        </li>
        <li>
          <P>
            <strong>Service Provider</strong> means any natural or legal person
            who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </P>
        </li>
        <li>
          <P>
            <strong>Usage Data</strong> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </P>
        </li>
        <li>
          <P>
            <strong>You</strong> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
          </P>
        </li>
      </ul>
      <H1>Collecting and Using Your Personal Data</H1>
      <H2>Types of Data Collected</H2>
      <H3>Personal Data</H3>
      <P>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </P>
      <ul>
        <li>
          <P>Usage Data</P>
        </li>
      </ul>
      <H3>Usage Data</H3>
      <P>Usage Data is collected automatically when using the Service.</P>
      <P>
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </P>
      <P>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile operating system, unique device identifiers and other
        diagnostic data.
      </P>
      <P>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </P>
      <H3>Information Collected while Using the Application</H3>
      <P>
        While using Our Application, in order to provide features of Our
        Application, We may collect, with Your prior permission:
      </P>
      <ul>
        <li>
          <P>Information regarding your location</P>
        </li>
      </ul>
      <P>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        Company's servers and/or a Service Provider's server or it may be simply
        stored on Your device.
      </P>
      <P>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </P>
      <H2>Use of Your Personal Data</H2>
      <P>The Company may use Personal Data for the following purposes:</P>
      <ul>
        <li>
          <P>
            <strong>To provide and maintain our Service</strong>, including to
            monitor the usage of our Service.
          </P>
        </li>
        <li>
          <P>
            <strong>For other purposes:</strong> We may use Your information for
            other purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Service, products, services, marketing and
            your experience.
          </P>
        </li>
      </ul>
      <P>We may share Your personal information in the following situations:</P>
      <ul>
        <li>
          <P>
            <strong>With Service Providers:</strong> We may share Your personal
            information with Service Providers to monitor and analyze the use of
            our Service, to contact You.
          </P>
        </li>
        <li>
          <P>
            <strong>With Your consent:</strong> We may disclose Your personal
            information for any other purpose with Your consent.
          </P>
        </li>
      </ul>
      <H2>Retention of Your Personal Data</H2>
      <P>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </P>
      <P>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </P>
      <H2>Transfer of Your Personal Data</H2>
      <P>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </P>
      <P>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </P>
      <P>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </P>
      <H2>Disclosure of Your Personal Data</H2>
      <H3>Business Transactions</H3>
      <P>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </P>
      <H3>Law enforcement</H3>
      <P>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </P>
      <H3>Other legal requirements</H3>
      <P>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </P>
      <ul>
        <li>
          <P>Comply with a legal obligation</P>
        </li>
        <li>
          <P>Protect and defend the rights or property of the Company</P>
        </li>
        <li>
          <P>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </P>
        </li>
        <li>
          <P>
            Protect the personal safety of Users of the Service or the public
          </P>
        </li>
        <li>
          <P>Protect against legal liability</P>
        </li>
      </ul>
      <H2>Security of Your Personal Data</H2>
      <P>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </P>
      <H1>Children's Privacy</H1>
      <P>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 13. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to
        remove that information from Our servers.
      </P>
      <P>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </P>
      <H1>Links to Other Websites</H1>
      <P>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </P>
      <P>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </P>
      <H1>Changes to this Privacy Policy</H1>
      <P>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </P>
      <P>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </P>
      <P>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </P>
      <H1>Contact Us</H1>
      <P>
        If you have any questions about this Privacy Policy, You can contact us:
      </P>
      <ul>
        <li>
          <P>By email: alexis.regnaud@gmail.com</P>
        </li>
        <li>
          <P>
            By visiting this page on our website:{" "}
            <a
              href="https://www.linkedin.com/in/alexisregnaud/"
              rel="external nofollow noopener"
              target="_blank"
            >
              https://www.linkedin.com/in/alexisregnaud/
            </a>
          </P>
        </li>
      </ul>
    </Container>
  </Layout>
);

export default AppPrivacyPolicy;
